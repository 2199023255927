import _slicedToArray from"@babel/runtime/helpers/esm/slicedToArray";import"core-js/modules/es.array.slice.js";import"core-js/modules/es.array.concat.js";import"core-js/modules/es.string.repeat.js";/**
 * Obfuscate an email address with asteriks.
 *
 * @param email String
 * @returns String
 *
 * obfuscateEmail("foo@bar.com")
 * => f**@b**.com
 */function obfuscateEmail(email){var _email$split=email.split('@'),_email$split2=_slicedToArray(_email$split,2),username=_email$split2[0],domain=_email$split2[1];// Split the domain (example.com,foo.bar.com) by any periods.
var partsOfDomain=domain.split('.');// Set the ending (com,org,net,etc)
var domainEnding=partsOfDomain[partsOfDomain.length-1];// Join the rest of the domain, minus the ending. This is needed in case
// a domain has multiple parts (subdomains)
// ie: example@foo.bar.com
var domainToObfuscate=partsOfDomain.slice(0,partsOfDomain.length-1).join('.');var obfuscatedUsername="".concat(username[0]).concat('*'.repeat(username.length-1));var obfuscatedDomain="".concat(domainToObfuscate[0]).concat('*'.repeat(domainToObfuscate.length-1));return"".concat(obfuscatedUsername,"@").concat(obfuscatedDomain,".").concat(domainEnding);}export{obfuscateEmail};